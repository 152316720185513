import React from 'react';
import styled from 'styled-components';

type Props = {
  spacerText?: string;
};

const HrSpacer = ({ spacerText }: Props) => {
  const Spacer = styled.div`
    padding: 1rem 0;
    p {
      border-bottom: 1px solid #ccc;
      line-height: 0.1rem;
      text-align: center;
      span {
        padding: 0.5rem;
        background: #fff;
      }
    }
  `;

  return (
    <Spacer>
      <p>{spacerText && <span>{spacerText}</span>}</p>
    </Spacer>
  );
};

export default HrSpacer;
