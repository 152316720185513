import {
  create,
  get,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
} from '@github/webauthn-json/browser-ponyfill';
import type { LoginResponse } from '../types/login-form-types';

const passkeyHost = `${process.env.GATSBY_API_URL}/api`;
const registerPasskeyRoute = `${passkeyHost}/webauthn_credentials`;
const verifyRegisterPasskeyRoute = `${passkeyHost}/webauthn_credentials/verify`;
const loginWithPasskeyRoute = `${passkeyHost}/passkey`;
const verifyLoginWithPasskeyRoute = `${passkeyHost}/verify`;

/**
 * Registers a Passkey via the Momentvm API and return true if successful
 * @param email The user's username
 * @returns {boolean}
 */
export const registerPasskey = async (email: string): Promise<boolean> => {
  const request = fetch(registerPasskeyRoute, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      apiToken: localStorage.getItem('apiToken') || '',
    },
    body: JSON.stringify({
      email,
    }),
  });

  const json = await (await request).json();
  const options = parseCreationOptionsFromJSON(json);
  const response = await create(options);

  const result = await fetch(verifyRegisterPasskeyRoute, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      apiToken: localStorage.getItem('apiToken') || '',
    },
    body: JSON.stringify(response),
  });

  if (!result.ok)
    throw new Error('There was a problem registering your passkey');

  return result.ok;
};

/**
 * Authenticates a Passkey via the Momentvm API
 * @param email The user's username
 * @returns {{status: boolean, response: LoginResponse} - JSON payload including the JWT token
 */
export const authenticateWithPasskey = async (): Promise<LoginResponse> => {
  const request = await fetch(loginWithPasskeyRoute, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
    },
  });

  if (!request.ok) {
    throw new Error(
      'There was an issue initiating your Passkey Login. Please try again.',
    );
  }

  const challenge = request.headers.get('X-Webauthn-Challenge');
  const json = await request.json();
  const options = parseRequestOptionsFromJSON(json);
  const response = await get(options);

  const result = await fetch(verifyLoginWithPasskeyRoute, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Webauthn-Challenge': challenge || '',
      'Access-Control-Allow-Headers': '*',
    },
    body: JSON.stringify(response),
  });

  const data = await result.json();

  if (!result.ok)
    throw new Error(
      'There was an issue verifying your Passkey. Please try again.',
    );

  return data;
};
